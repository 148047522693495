<template>
  <div>
    <template>
      <v-row justify="center">
        <v-dialog v-model="confirmationResultsDialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Results</span>
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th id="email" class="text-left">Email</th>
                      <th id="result" class="text-left">Result</th>
                      <th id="remarks" class="text-left">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in confirmationResults" :key="item.user">
                      <td>{{ item.user }}</td>
                      <td :class="item.status === 'Success' ? 'success--text' : 'error--text'">{{ item.status }}</td>
                      <td :class="item.status === 'Success' ? 'success--text' : 'error--text'">{{ item.remarks }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="confirmationResultsDialog = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <confirmation-dialog
      v-if="selectedAction === '0' && selectedUsers.length"
      v-model="confirmationDialogIsOpen"
      :loading="isConfirmationDialogLoading"
      @closeConfirmationDialog="closeConfirmationDialog()"
    >
      <div slot="header">Confirm Approval?</div>
      <div slot="body">An email will be sent to the selected users informing them that their attendance has been <strong>Approved</strong>.</div>
      <div slot="actions">
        <v-btn text color="error" :disabled="isConfirmationDialogLoading" @click="closeConfirmationDialog()">Cancel</v-btn>
        <v-btn text color="primary" :disabled="isConfirmationDialogLoading" @click="sendAttendanceApprovedEmail()">Confirm</v-btn>
      </div>
    </confirmation-dialog>
    <confirmation-dialog
      v-if="selectedAction === '1' && selectedUsers.length"
      v-model="confirmationDialogIsOpen"
      :loading="isConfirmationDialogLoading"
      @closeConfirmationDialog="closeConfirmationDialog()"
    >
      <div slot="header">Confirm Decline?</div>
      <div slot="body">
        An email will be sent to the selected users informing them that their attendance has been <strong>Declined</strong>.
        <p>Please provide the reason why the user will be rejected. This would not be sent to the user and will only be used for future reference.</p>
        <v-form ref="reasonForRejectionForm" v-model="rejectionFormIsValid">
          <v-select v-model="reasonForRejection" :items="rejectionReasons" :rules="reasonForRejectionRules" label="Reason for rejection" dense outlined></v-select>
          <v-textarea v-if="reasonForRejection === 'Others'" v-model="otherReason" :rules="otherReasonForRejectionRules" outlined name="input-7-4" label="Notes"></v-textarea>
        </v-form>
      </div>
      <div slot="actions">
        <v-btn text color="error" :disabled="isConfirmationDialogLoading" @click="closeConfirmationDialog()">Cancel</v-btn>
        <v-btn text color="primary" :disabled="isConfirmationDialogLoading" @click="sendDeclineEmail()">Confirm</v-btn>
      </div>
    </confirmation-dialog>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog()" @keydown.esc="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Send Bulk Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :disabled="selectedUsers.length ? false : true" @click="confirmBulkConfirmationDialog()">
              <v-icon class="me-2">mdi-send</v-icon>
              Send
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="lighten-5">
          <v-row>
            <v-col cols="6">
              <v-form ref="Form" lazy-validation class="pe-5">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p class="mb-2">Action Type:</p>
                      <v-radio-group v-model="selectedAction" row>
                        <v-row>
                          <v-col cols="6" md="6" sm="12">
                            <v-card elevation="0">
                              <v-alert
                                outlined
                                :prominent="selectedAction === '0' ? true : false"
                                :text="selectedAction === '0' ? true : false"
                                :color="selectedAction === '0' ? 'success' : 'grey'"
                                class="mb-0"
                              >
                                <v-radio value="0" class="me-0" color="success">
                                  <template v-slot:label>
                                    <div :class="selectedAction === '0' ? 'success--text ps-2 py-3 ' : 'ps-2 py-3'">
                                      <h3>Approve</h3>
                                      <p class="text-caption mb-0 pt-2">Send approval notification to selected users</p>
                                    </div>
                                  </template>
                                </v-radio>
                              </v-alert>
                            </v-card>
                          </v-col>
                          <v-col cols="6" md="6" sm="12">
                            <v-card elevation="0">
                              <v-alert
                                outlined
                                :prominent="selectedAction === '1' ? true : false"
                                :text="selectedAction === '1' ? true : false"
                                :color="selectedAction === '1' ? 'error' : 'grey'"
                                class="mb-0"
                              >
                                <v-radio value="1" class="me-0" color="error">
                                  <template v-slot:label>
                                    <div :class="selectedAction === '1' ? 'error--text ps-2 py-3 ' : 'ps-2 py-3 '">
                                      <h3>Decline</h3>
                                      <p class="text-caption mb-0 pt-2">Send decline notification to selected users</p>
                                    </div>
                                  </template>
                                </v-radio>
                              </v-alert>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <p class="mb-2">Emails:</p>
                      <v-autocomplete
                        v-model="selectedEmails"
                        :items="emailList"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        small-chips
                        outlined
                        :search-input.sync="emailSearch"
                        :loading="isEmailSearchLoading"
                        return-object
                        item-text="email"
                        item-value="userId"
                        hide-no-data
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                      <p class="mb-2">Roles:</p>
                      <v-select v-model="selectedRoles" :items="roleList" chips clearable deletable-chips multiple small-chips outlined> </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <v-container>
                <p class="mb-2">Summary:</p>
                <v-card elevation="0" class="mt-5 ps-5">
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense></v-text-field>
                  </v-card-title>
                  <v-data-table show-group-by item-key="userId" :headers="headers" :items="selectedUsers" :search="search" dense :loading="isSummaryLoading" loading-text="Loading... Please wait">
                    <template v-slot:item.actions="{ item }">
                      <v-icon small @click="removeFromSelectedUsers(item)"> mdi-close </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';

import ConfirmationDialog from '@/components/Global/ConfirmationDialog';

import { roles as roleList } from '../data/roles';

export default {
  components: {
    ConfirmationDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isSummaryLoading: false,
    snackbarTimeout: 5000,
    snackbarText: '',
    snackbar: false,
    emailSearch: '',
    isEmailSearchLoading: false,
    confirmationDialogIsOpen: false,
    backendEndpoint: process.env.VUE_APP_BACKEND_ENDPOINT,
    selectedAction: '0',
    selectedEmails: [],
    previousSelectedEmails: [],
    previousSelectedRoles: [],
    selectedRoles: [],
    emailList: [],
    roleList,
    search: '',
    headers: [
      { text: 'UserId', value: 'userId', groupable: false, align: ' d-none' },
      { text: 'Email', value: 'email', groupable: false },
      { text: 'Role', value: 'role' },
      { text: 'Remove', value: 'actions', groupable: false },
    ],
    selectedUsers: [],
    isConfirmationDialogLoading: false,
    confirmationResultsDialog: false,
    confirmationResults: [],
    rejectionFormIsValid: true,
    reasonForRejectionRules: [(v) => !!v || 'This field is required'],
    otherReasonForRejectionRules: [(v) => !!v || 'This field is required'],
    rejectionReasons: ['User is a student', 'Duplicate record', 'Competitor', 'Others'],
    reasonForRejection: '',
    otherReason: '',
  }),
  watch: {
    emailSearch(val) {
      if (this.isEmailSearchLoading) {
        return null;
      }
      if (val != null && val.length > 2) {
        this.isEmailSearchLoading = true;
        return this.searchByEmail(val);
      }
      return null;
    },
    selectedEmails(emails) {
      const added = emails.filter((val) => !this.previousSelectedEmails.includes(val));
      const removed = this.previousSelectedEmails.filter((val) => !emails.includes(val)).map((item) => item.email);
      const [addedData] = added;
      if (addedData) {
        const selectedUserEmails = this.selectedUsers.map((item) => item.email);
        if (!selectedUserEmails.includes(addedData.email)) {
          this.selectedUsers.push({
            userId: addedData.userId,
            name: `${addedData.firstName} ${addedData.lastName}`,
            email: addedData.email,
            role: addedData.role,
          });
        }
      }
      if (removed && removed.length) {
        const filteredUsers = this.selectedUsers.filter((user) => !removed.includes(user.email));
        this.selectedUsers = filteredUsers;
      }
      this.previousSelectedEmails = emails;
    },
    selectedRoles(roles) {
      const added = roles.filter((val) => !this.previousSelectedRoles.includes(val));
      const removed = this.previousSelectedRoles.filter((val) => !roles.includes(val));
      const selectedEmails = this.selectedEmails.map((item) => item.email);
      const [addedData] = added;
      if (addedData) {
        this.searchByRole(addedData).then((result) => {
          const users = result.flatMap((user) => {
            if (!selectedEmails.includes(user.email)) {
              return {
                userId: user.userId,
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                role: user.role,
              };
            }
            return [];
          });

          this.selectedUsers = [...this.selectedUsers, ...users];
        });
      }
      if (removed && removed.length) {
        const filteredUsers = this.selectedUsers.filter((user) => !removed.includes(user.role) || selectedEmails.includes(user.email));
        this.selectedUsers = filteredUsers;
      }
      this.previousSelectedRoles = roles;
    },
  },
  methods: {
    closeConfirmationDialog() {
      this.confirmationDialogIsOpen = false;
    },
    closeDialog() {
      this.$emit('closeBulkConfirmationDialog');
    },
    refreshRegistrantList() {
      this.$emit('getRegistrantList');
    },
    confirmBulkConfirmationDialog() {
      this.confirmationDialogIsOpen = true;
    },
    resetSelected() {
      this.selectedEmails = [];
      this.emailList = [];
      this.selectedRoles = [];
      this.selectedUsers = [];
    },
    removeFromSelectedUsers(item) {
      const filteredUsers = this.selectedUsers.filter((user) => user.email !== item.email);
      this.selectedUsers = filteredUsers;

      const filteredUserEmails = this.selectedEmails.filter((user) => user.email !== item.email);
      this.selectedEmails = filteredUserEmails;
    },
    async searchByEmail(search) {
      this.isSummaryLoading = true;
      try {
        const res = await axios.get(
          `${this.backendEndpoint}/api/admin/registrants?year=2024&year=2023&year=2022&year=2021&year=2020&year=2019&year=2018&applicationStatus=Pending&emailConfirmed=true&search=${search}`,
        );
        this.emailList = res.data;
        this.emailList = [...this.emailList, ...this.selectedEmails];
      } finally {
        this.isEmailSearchLoading = false;
        this.isSummaryLoading = false;
      }
    },
    async searchByRole(role) {
      this.isSummaryLoading = true;
      try {
        const res = await axios.get(
          `${this.backendEndpoint}/api/admin/registrants?year=2024&year=2023&year=2022&year=2021&year=2020&year=2019&year=2018&applicationStatus=Pending&emailConfirmed=true&role=${role}`,
        );
        return res.data;
      } catch (err) {
        return null;
      } finally {
        this.isSummaryLoading = false;
      }
    },
    async sendAttendanceApprovedEmail() {
      this.confirmationResults = [];
      try {
        const payload = {
          ids: this.selectedUsers.map((item) => item.userId),
        };
        this.isConfirmationDialogLoading = true;
        await axios.post(`${this.backendEndpoint}/api/admin/confirm-multi`, payload).then((result) => {
          if (result) {
            result.data.failures.forEach((failure) => {
              this.confirmationResults.push({
                user: failure.user,
                status: 'Failed',
                remarks: failure.message,
              });
            });

            result.data.users.forEach((user) => {
              this.confirmationResults.push({
                user,
                status: 'Success',
                remarks: 'Confirmation email has been sent',
              });
            });
            this.confirmationResultsDialog = true;
          }
          this.isConfirmationDialogLoading = false;
          this.refreshRegistrantList();
          this.resetSelected();
          this.closeConfirmationDialog();
        });
      } catch {
        this.snackbarText = 'An error was encountered while sending the confirmation. Please try again.';
        this.snackbar = true;
        this.isConfirmationDialogLoading = false;
        this.resetSelected();
        this.closeConfirmationDialog();
      }
    },
    async sendDeclineEmail() {
      this.confirmationResults = [];
      try {
        if (!this.$refs.reasonForRejectionForm.validate()) return;
        const payload = {
          ids: this.selectedUsers.map((item) => item.userId),
          type: 'Decline',
          reason: this.reasonForRejection,
          remarks: this.otherReason,
        };
        this.isConfirmationDialogLoading = true;
        await axios.post(`${this.backendEndpoint}/api/admin/confirm-multi`, payload).then((result) => {
          if (result) {
            result.data.failures.forEach((failure) => {
              this.confirmationResults.push({
                user: failure.user,
                status: 'Failed',
                remarks: failure.message,
              });
            });

            result.data.users.forEach((user) => {
              this.confirmationResults.push({
                user,
                status: 'Success',
                remarks: 'Rejection email has been sent',
              });
            });
            this.confirmationResultsDialog = true;
          }
          this.isConfirmationDialogLoading = false;
          this.refreshRegistrantList();
          this.resetSelected();
          this.closeConfirmationDialog();
        });
      } catch {
        this.snackbarText = 'An error was encountered while sending the confirmation. Please try again.';
        this.snackbar = true;
        this.isConfirmationDialogLoading = false;
        this.resetSelected();
        this.closeConfirmationDialog();
      }
    },
  },
};
</script>
