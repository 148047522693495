export const roles = [
  'Account Associate/Manager',
  'Accountant',
  'Administrator',
  'Analyst',
  'Anti,Malware Specialist / Analyst',
  'Artist',
  'Associate',
  'Athlete',
  'Audit Manager',
  'BPO Professional',
  'Banking / Finance Professional',
  'Business Development Manager',
  'Chairperson',
  'Change Manager',
  'Chef / Culinary Specialist',
  'Chief Operations Officer',
  'Chief Technology Officer',
  'Communications Officer / Manager',
  'Computer Operator',
  'Consultant',
  'Content Creator / Developer',
  'Coordinator',
  'Database Administrator',
  'Database Analyst / Consultant',
  'Dean',
  'Department Chair',
  'Developer / Programmer',
  'Director',
  'Editor / Publisher',
  'Engineer / Mechanic / Technician',
  'Entrepreneur / Business Owner / Investor',
  'Finance Officer / Manager',
  'Forensics Specialist / Analyst',
  'Founder / Co - Founder',
  'General Manager',
  'Graphic Designer / Artist',
  'HR Officer / Manager',
  'IT Engineer / Staff',
  'IT Officer / Administrator',
  'IT Research and Development Specialist',
  'Information Security Professional',
  'Information Systems Analyst / Officer',
  'Instructor',
  'Medical Doctor / Professional',
  'Network Administrator',
  'Network Analyst / Engineer',
  'Network Security Professional',
  'Operations Officer / Manager',
  'Police / Military Officer',
  'Postgraduate',
  'President / CEO',
  'Principal',
  'Professor',
  'Program / Learning Coordinator',
  'Project Coordinator / Manager',
  'QA / Software Testing Analyst / Engineer',
  'Reports Developer',
  'Risk & Compliance Officer / Manager',
  'Sales / Marketing Manager',
  'Scientist / Inventor',
  'Security Administrator',
  'Security Analyst / Consultant',
  'Service / Helpdesk Staff',
  'Service Manager',
  'Software Engineer',
  'Solution Architect',
  'Supervisor / Team Leader',
  'System Administrator',
  'Technical Support Staff',
  'Threat Analyst / Researcher',
  'Training Officer / Manager',
  'Undergraduate',
  'Vice President',
  'Website Designer',
  'Writer / Journalist',
  'Others',
];
